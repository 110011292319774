import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"

import FeatureImg1 from "../assets/images/features-details-image/feature1.png"
import FeatureImg2 from "../assets/images/features-details-image/feature2.png"

const FeatureDetails = () => (
  <Layout>
    <SEO title="Infrastructure in Google Cloud" />

    <Navbar />

    <PageBanner pageTitle="Infrastructure in Google Cloud" />

    <div className="features-details-area ptb-80">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 features-details-image">
            {/* <img
              src={FeatureImg1}
              className="animate__animated animate__fadeInUp"
              alt="image"
            /> */}
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Ltr3xYyV2Q0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div className="col-lg-6 features-details">
            <div className="features-details-desc">
              <h5>
                This second course reviews implementing storage models,
                different managed-service application options, and security
                administration in Google Cloud. By the end of this course,
                learners will be able to articulate these concepts and
                demonstrate some hands-on skills.
              </h5>
            </div>
          </div>
        </div>

        <div className="separate"></div>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default FeatureDetails
